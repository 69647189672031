import React, {useEffect, useState} from 'react';
import {
    Container,
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    CardActions,
    Button
} from '@mui/material';
import {useTranslation} from "react-i18next";
import {useMembershipPlans} from "../../mocks/plans";
import {clientId} from "../../App"
import {handleGoogleSuccess} from "../../common/api";
import {useNavigate} from "react-router-dom";
import {send_registration_email} from "../../common/email";
import LoginDialog from "../Authentication/LoginDialog";

export default function Membership() {
    const {t, i18n} = useTranslation();
    const membershipPlans = useMembershipPlans();
    const [selectedPlan, setSelectedPlan] = useState(membershipPlans[1]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useState(false);

    if (selectedPlan.services.en !== undefined) {
        selectedPlan.services = i18n.language.includes("en") ? selectedPlan.services.en : i18n.language.includes("am") ? selectedPlan.services.hy : selectedPlan.services.ru;
    }
    useEffect(() => {
        setSelectedPlan(membershipPlans[1]);
    }, [i18n.language]);
    const handlePlanClick = (plan) => {
        setSelectedPlan(plan);
    };

    const handlePlanSelect = (plan) => {
        setSelectedPlan(plan);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    const sendRegistrationEmail = (email, name) => {
        const templateParams = {
            to_email: email,
            user_name: name
        };

        const response = send_registration_email(templateParams)
        if (response) {
            console.log('Registration email sent successfully');
        } else {
            console.log('Failed to send registration email');
        }
    };
    const googleSuccessHandler = (r) => {
        setLoading(true);
        handleGoogleSuccess(r).then(r => {
                setLoading(false);
                if (r !== null) {
                    const state = {
                        title: selectedPlan.title,
                        planName: selectedPlan.planName,
                        price: selectedPlan.price,
                        services: selectedPlan.services.map(service => ({
                            name: service.name,
                            description: service.description
                        }))
                    }
                    const user = r.user.user
                    if (/^7[1-9]00\d{9}$/.test(r.user.id_number)) {
                        navigate('/currentPlan', {
                            state: state
                        });
                    } else {
                        if (r.newUser) {
                            sendRegistrationEmail(user.email, user.first_name);
                        }
                        navigate('/profile', {
                            state: state
                        });
                    }
                } else {
                    console.log('Failed to authenticate with backend');
                }
            }
        ).catch(() => {
            setLoading(false); // Stop loading if there's an error
            console.log('Failed to authenticate with backend');
        });
    }

    useEffect(() => {
        if (loggedIn) {
            setLoading(false);
            const r = localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')) : null;
            const state = {
                title: selectedPlan.title,
                planName: selectedPlan.planName,
                price: selectedPlan.price,
                services: selectedPlan.services.map(service => ({
                    name: service.name,
                    description: service.description
                }))
            }
            if (r !== null) {
                const user = r.user.user
                if (/^7[1-9]00\d{9}$/.test(r.id_number)) {
                    navigate('/currentPlan', {
                        state: state
                    });
                } else {
                    if (r.newUser) {
                        sendRegistrationEmail(user.email, user.first_name);
                    }
                    navigate('/profile', {
                        state: state
                    });
                }
            } else {
                console.log('Failed to authenticate with backend');
                setLoggedIn(false);
            }
        }
    }, [loggedIn]);


    const getBackgroundColor = (plan) => {
        if (selectedPlan.planName === plan.planName && selectedPlan.planName === membershipPlans[0].planName) return '#2476d238';
        if (selectedPlan.planName === plan.planName && selectedPlan.planName === membershipPlans[1].planName) return '#ffb40078';
        if (selectedPlan.planName === plan.planName && selectedPlan.planName === membershipPlans[2].planName) return '#f4433663';

        return '#fff';
    };

    return (
        <Container maxWidth="lg">
            <Box
                sx={{
                    my: 4,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right',
                }}
                onClick={() => {
                    navigate('/giftCard');
                }}
            >
                <img
                    src={'/icons/gift-card.webp'}
                    style={{
                        width: 50,
                        height: 'auto',
                        margin: 0,
                        padding: 0,
                    }}
                    alt={'Gift Card'}
                />
                <Button
                    variant="h4"
                    component="h1"
                    sx={{
                        marginLeft: 1, 
                        borderRadius: '16px',
                        ":hover": {
                            backgroundColor: "#1976d2",
                            color: "#fff"
                        }
                        
                    }}
                >
                    {t('giftCard.buy')}
                </Button>
            </Box>
            
            <Box sx={{my: 4, textAlign: 'center'}}>
                <Typography variant="h4" component="h1" gutterBottom>
                    {t('membership.plans.title')}
                </Typography>
                <Grid container spacing={4}>
                    {membershipPlans.map((plan, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    cursor: 'pointer',
                                    backgroundColor: getBackgroundColor(plan),
                                    boxShadow: 'none',
                                    transition: 'transform 0.2s, box-shadow 0.2s',
                                    padding: '16px',
                                    borderRadius: '16px 16px 0 0',
                                }}
                                onClick={() => handlePlanClick(plan)}
                            >
                                <CardContent sx={{textAlign: 'center', paddingBottom: '0'}}>
                                    {plan.icon}
                                    <Typography variant="h5" component="h2" sx={{fontWeight: 'bold', mt: 2}}>
                                        {plan.title || 'No Title Available'}
                                    </Typography>
                                    <Typography variant="body1" sx={{mt: 2, color: '#757575'}}>
                                        {plan.description || 'No Description Available'}
                                    </Typography>
                                    <Typography variant="h6" component="p"
                                                sx={{mt: 2, color: '#1976d2', fontWeight: 'bold'}}>
                                        {plan.price || 'No Price Available'}
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{justifyContent: 'center', paddingTop: '8px'}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{borderRadius: '20px', padding: '8px 16px'}}
                                        onClick={() => handlePlanSelect(plan)}
                                    >
                                        {t('membership.plans.select')}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                {selectedPlan && (
                    <Box sx={{
                        p: 4,
                        textAlign: 'center',
                        backgroundColor: getBackgroundColor(selectedPlan),
                        borderRadius: '0 0 16px 16px',
                    }}>
                        <Typography variant="h5" component="h2" gutterBottom sx={{fontWeight: 'bold'}}>
                            {t('membership.plans.selectedPlan', {plan: selectedPlan.title})}
                        </Typography>
                        <Grid container spacing={3} sx={{margin: '0 auto', maxWidth: '1200px'}}>
                            {selectedPlan.services.map((service, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            border: '2px solid #fff',
                                            borderRadius: '8px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            p: 2,
                                            mr: '2rem',
                                            height: '100%',
                                            backgroundColor: service.name === 'Help my friend' ? 'rgba(34,255,0, 0.47)' : service.name === 'BONUS' ? 'rgba(166,255,251, 0.82)' : 'inherit'
                                        }}
                                    >
                                        <img src={service.img} alt={service.name}
                                             style={{width: '80px', height: 'auto', marginBottom: '1rem'}}/>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="#424242"
                                            fontSize="1.2rem"
                                            sx={{mb: 1}}
                                        >
                                            {service.name}
                                        </Typography>
                                        <Typography variant="body2" color="#757575">
                                            {service.description}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}

                <LoginDialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    loading={loading}
                    googleSuccessHandler={googleSuccessHandler}
                    clientId={clientId}
                    setLoggedIn={setLoggedIn}
                />
            </Box>
        </Container>
    );
}
