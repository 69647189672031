import React, {useState} from 'react';
import {
    Container,
    Box,
    Typography,
    TextField,
    Button,
    MenuItem,
    List,
    ListItem,
    ListItemText,
    Divider, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio
} from '@mui/material';
import {useMembershipPlans} from '../../mocks/plans';
import {useTranslation} from 'react-i18next';
import MessageDialog from '../Helpers/MessageDialog';
import {paymentIntentsUrl} from '../../common/config';

const GiftCard = () => {
    const membershipPlans = useMembershipPlans();
    const {t, i18n} = useTranslation();

    // State management
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [recipientName, setRecipientName] = useState('');
    const [recipientPhone, setRecipientPhone] = useState('');
    const [buyerName, setBuyerName] = useState('');
    const [buyerPhone, setBuyerPhone] = useState('');
    const [validRecipientName, setValidRecipientName] = useState(false);
    const [validRecipientPhone, setValidRecipientPhone] = useState(false);
    const [validBuyerName, setValidBuyerName] = useState(false);
    const [validBuyerPhone, setValidBuyerPhone] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [makePayment, setMakePayment] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('card');
    const [paymentData, setPaymentData] = useState(null)
    const [paymentLoading, setPaymentLoading] = useState(false)
    if (selectedPlan?.services.en !== undefined) {
        selectedPlan.services = i18n.language.includes("en") ? selectedPlan.services.en : i18n.language.includes("am") ? selectedPlan.services.hy : selectedPlan.services.ru;
    }
    React.useEffect(() => {
        if (!makePayment) return;
        const makePaymentRequest = async () => {
            try {
                const response = await fetch(paymentIntentsUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        payment_type: 'gift_card',
                        plan_id: selectedPlan?.planName === "STANDARD" ? 1 : selectedPlan?.planName === "PREMIUM" ? 2 : 3,
                        recipient_name: recipientName,
                        recipient_phone: recipientPhone,
                        buyer_name: buyerName,
                        buyer_phone: buyerPhone,
                        payment_method: paymentMethod,
                    }),
                });
                const data = await response.json();

                if (!response.ok) {
                    setDialogMessage(t('giftCard.dialog.paymentError'));
                    setDialogOpen(true);
                    setSuccess(false);
                    setMakePayment(false);
                    return;
                }

                setPaymentData(data);
            } catch (error) {
                console.error('Error making payment:', error.message);
                    setDialogMessage(t('giftCard.dialog.paymentError'));
                setDialogOpen(true);
                setSuccess(false);
                setMakePayment(false);
                setPaymentLoading(false)
            }
        };
        setPaymentLoading(true)
        
        makePaymentRequest();
        setPaymentLoading(false)
        
    }, [makePayment]); // Only trigger when makePayment changes


    // Handle payment data response
    React.useEffect(() => {
        console.log(paymentData);
        if (paymentData?.redirect_url) {
            if (paymentData.redirect_url.form_url === undefined) {
                window.location.href = paymentData.redirect_url;
            } else {
                localStorage.setItem('pxNumber', paymentData.redirect_url.px_number);
                window.location.href = paymentData.redirect_url.form_url;
            }
        }
    }, [paymentData]);
    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
    };
    // Validation functions
    const validateName = (value) => value.trim().length > 2;
    const validatePhone = (value) => /^\+?\d{7,}$/.test(value);

    // Form submission handler
    const handleSubmit = () => {
        if (
            !validRecipientName ||
            !validRecipientPhone ||
            !validBuyerName ||
            !validBuyerPhone ||
            !selectedPlan
        ) {
            setDialogMessage(t('giftCard.dialog.validationError'));
            setDialogOpen(true);
            setSuccess(false);
            return;
        }

        setMakePayment(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    return (
        <Container maxWidth="md" sx={{mt: 6}}>
            <Box
                sx={{
                    p: 4,
                    boxShadow: 4,
                    borderRadius: 3,
                    bgcolor: '#f9f9f9',
                    textAlign: 'center',
                }}
            >
                <Typography variant="h4" component="h1" gutterBottom>
                    {t('giftCard.title')}
                </Typography>

                {/* Plan Selection */}
                <TextField
                    select
                    label={t('giftCard.selectPlan')}
                    value={selectedPlan ? selectedPlan.title : ''}
                    onChange={(e) =>
                        setSelectedPlan(
                            membershipPlans.find((plan) => plan.title === e.target.value)
                        )
                    }
                    fullWidth
                    margin="normal"
                    required
                >
                    {membershipPlans.map((plan) => (
                        <MenuItem key={plan.planName} value={plan.title}>
                            {plan.icon} {plan.title} - {plan.price}
                        </MenuItem>
                    ))}
                </TextField>

                {selectedPlan && (
                    <Box sx={{mt: 2, p: 2, bgcolor: '#fff', borderRadius: 2}}>
                        <Typography variant="h6" gutterBottom>
                            {t('giftCard.selectedPlan')}
                        </Typography>
                        <Typography variant="body1">
                            <strong>{t('giftCard.planName')}:</strong> {selectedPlan.title}
                        </Typography>
                        <Typography variant="body1">
                            <strong>{t('giftCard.price')}:</strong> {selectedPlan.price}
                        </Typography>
                        <Divider sx={{my: 2}}/>
                        <List>
                            {selectedPlan.services.map((service, index) => (
                                <ListItem key={index}>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                                                {service.name}
                                            </Typography>
                                        }
                                        secondary={<Typography variant="body2">{service.description}</Typography>}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                )}

                {/* Recipient Details */}
                <Typography variant="h6" sx={{mt: 4}}>
                    {t('giftCard.recipientDetails')}
                </Typography>
                <TextField
                    label={t('giftCard.recipientName')}
                    value={recipientName}
                    onChange={(e) => setRecipientName(e.target.value)}
                    onBlur={() => setValidRecipientName(validateName(recipientName))}
                    fullWidth
                    margin="normal"
                    required
                    error={!validRecipientName}
                    helperText={!validRecipientName ? t('giftCard.nameError') : ''}
                />
                <TextField
                    label={t('giftCard.recipientPhone')}
                    value={recipientPhone}
                    onChange={(e) => setRecipientPhone(e.target.value)}
                    onBlur={() => setValidRecipientPhone(validatePhone(recipientPhone))}
                    fullWidth
                    margin="normal"
                    required
                    error={!validRecipientPhone}
                    helperText={!validRecipientPhone ? t('giftCard.phoneError') : ''}
                />

                {/* Buyer Details */}
                <Typography variant="h6" sx={{mt: 4}}>
                    {t('giftCard.buyerDetails')}
                </Typography>
                <TextField
                    label={t('giftCard.buyerName')}
                    value={buyerName}
                    onChange={(e) => setBuyerName(e.target.value)}
                    onBlur={() => setValidBuyerName(validateName(buyerName))}
                    fullWidth
                    margin="normal"
                    required
                    error={!validBuyerName}
                    helperText={!validBuyerName ? t('giftCard.nameError') : ''}
                />
                <TextField
                    label={t('giftCard.buyerPhone')}
                    value={buyerPhone}
                    onChange={(e) => setBuyerPhone(e.target.value)}
                    onBlur={() => setValidBuyerPhone(validatePhone(buyerPhone))}
                    fullWidth
                    margin="normal"
                    required
                    error={!validBuyerPhone}
                    helperText={!validBuyerPhone ? t('giftCard.phoneError') : ''}
                />
                {/* Payment Method Selection */}
                <FormControl component="fieldset" sx={{mb: 2, width: '100%', pr: {md: 20, xs: 0}, pl: {md: 20, xs: 0}}}>
                    <FormLabel component="legend" sx={{mb: 2, textAlign: 'center', fontWeight: 'bold'}}>
                        {t('buyPage.selectPaymentMethod')}
                    </FormLabel>
                    <RadioGroup
                        aria-label="payment-method"
                        name="payment-method"
                        value={paymentMethod}
                        onChange={handlePaymentMethodChange}
                        row
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center', // Aligns radio buttons and images vertically
                        }}
                    >
                        {/* Telcell Section */}
                        <Box
                            sx={{
                                textAlign: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <FormControlLabel
                                value="telcell"
                                control={<Radio/>}
                                label={t('buyPage.paymentMethod.telcell')}
                                sx={{marginBottom: '8px'}}
                            />
                            {/* Telcell Logo */}
                            <img
                                src='/images/Telcell-Wallet_Logo_Main_RGB.png'
                                alt="TELCELL"
                                style={{
                                    width: '120px', // Adjusted to be consistent with the credit card size
                                    height: 'auto',
                                    filter: paymentMethod === 'telcell' ? 'none' : 'grayscale(100%)', // Apply greyscale if not selected
                                    opacity: paymentMethod === 'telcell' ? 1 : 0.5, // Lower opacity if not selected
                                    marginTop: '8px',
                                }}
                            />
                        </Box>

                        {/* Credit Card Section */}
                        <Box
                            sx={{
                                textAlign: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <FormControlLabel
                                value="card"
                                control={<Radio/>}
                                label={t('buyPage.paymentMethod.creditCard')}
                                sx={{marginBottom: '8px'}}
                            />
                            {/* Credit Card Logo */}
                            <img
                                src='/images/credit-card.svg'
                                alt="Credit Card"
                                style={{
                                    width: '80px', // Adjusted for consistency
                                    height: 'auto',
                                    filter: paymentMethod === 'card' ? 'none' : 'grayscale(100%)', // Apply greyscale if not selected
                                    opacity: paymentMethod === 'card' ? 1 : 0.5, // Lower opacity if not selected
                                    marginTop: '8px',
                                }}
                            />
                        </Box>
                    </RadioGroup>
                </FormControl>
                {/* Submit Button */}
                <Button
                    variant="contained"
                    color="primary"
                    sx={{mt: 4}}
                    onClick={handleSubmit}
                    disabled={paymentLoading}
                >
                    {t('giftCard.buy')}
                </Button>

                <MessageDialog
                    open={dialogOpen}
                    handleClose={handleCloseDialog}
                    success={success}
                    successTitle={t('giftCard.dialog.successTitle')}
                    failureTitle={t('giftCard.dialog.failureTitle')}
                    message={dialogMessage}
                />
            </Box>
        </Container>
    );
};

export default GiftCard;
