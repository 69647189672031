import {useState, useEffect} from 'react';
import {originUrl, refreshUrl} from "./config";
import React from 'react'
import {jwtDecode} from 'jwt-decode';

const refreshToken = async () => {
    try {
        const refresh = localStorage.getItem('refresh');
        const response = await fetch(refreshUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({refresh}),
        });

        if (!response.ok) {
            throw new Error('Failed to refresh token');
        }

        const data = await response.json();
        localStorage.setItem('access', data.access);
        return data.access;
    } catch (error) {
        console.error('Failed to refresh token', error);
        return null;
    }
};

const isTokenExpired = (token) => {
    if (!token) return true; // If there's no token, treat it as expired

    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds

        // Check if the token's expiration time is in the past
        return decodedToken.exp < currentTime;
    } catch (e) {
        return true; // In case of any error (e.g., invalid token), treat it as expired
    }
};

const getAccessToken = async () => {
    let access = localStorage.getItem('access');

    // Check if the token is expired
    if (!access || isTokenExpired(access)) {
        access = await refreshToken(); // Refresh the token if it's missing or expired
    }

    return access;
};

// const useFetch = (url, options) => {
//     const [data, setData] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//
//     const fetchData = useCallback(async (url, options) => {
//         let accessToken = await getAccessToken();
//
//         const fetchOptions = {
//             ...options,
//             headers: {
//                 ...options.headers,
//                 'Authorization': `Bearer ${accessToken}`,
//             },
//         };
//
//         try {
//             const response = await fetch(url, fetchOptions);
//
//             if (!response.ok) {
//                 const errorData = await response.json();
//                 if (errorData.code === 'token_not_valid' && errorData.messages[0].token_type === 'access') {
//                     accessToken = await refreshToken();
//                     if (accessToken) {
//                         fetchOptions.headers['Authorization'] = `Bearer ${accessToken}`;
//                         const retryResponse = await fetch(url, fetchOptions);
//                         if (retryResponse.ok) {
//                             setLoading(false);
//                             return await retryResponse.json();
//                         }
//                     }
//                 }
//                 throw new Error('Failed to fetch data');
//             }
//             return await response.json();
//         } catch (err) {
//             setError(err);
//         } finally {
//             setLoading(false);
//         }
//     }, []);
//
//     useEffect(() => {
//         fetchData(url, options).then((d) => {
//             // console.log(d);
//             setData(d)
//         });
//     }, []);
//     return {data, loading, error};
// };
const useFetch = (url, options, deps = []) => {
    const [response, setResponse] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = useState(false);
    const doSomething = async () => {
        let accessToken = await getAccessToken();
        const fetchOptions = {
            ...options,
            headers: {
                ...options.headers,
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': originUrl,                
            },
        };
        const fetchData = async () => {
            setLoading(true);
            try {
                const res = await fetch(url, fetchOptions);
                if (!res.ok) {
                    if (res.status === 500) {
                        throw new Error("Failed to get data from server")
                    }
                    const errorData = await res.json();
                    if (errorData.code === 'token_not_valid' && errorData.messages[0].token_type === 'access') {
                        accessToken = await refreshToken();
                        if (accessToken) {
                            fetchOptions.headers['Authorization'] = `Bearer ${accessToken}`;
                            const retryResponse = await fetch(url, fetchOptions);
                            if (retryResponse.ok) {
                                return await retryResponse.json();
                            }
                        }
                    }
                    if (res.status === 400) {
                        setError(errorData);
                    } else if (res.status === 404) {
                        setError(errorData);
                    } 
                    else {
                        throw new Error('Failed to fetch data');
                    }
                } else {
                    const json = await res.json();
                    setResponse(json);
                }
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        url && await fetchData();
    }
    useEffect(() => {
        doSomething()
    }, [...deps]);
    return {response, loading, error};
};
export default useFetch;
