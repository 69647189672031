import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LoginDialog from "../Authentication/LoginDialog";
import { useGiftCardUrl } from "../../common/config";
import useFetch from "../../common/useFetch";
import {clientId} from "../../App";
import {handleGoogleSuccess} from "../../common/api";

const UseGiftCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loginDialogOpen, setLoginDialogOpen] = useState(false); // Track login dialog state
    const [dialogOpen, setDialogOpen] = useState(false);
    const [giftCardCode, setGiftCardCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Track user login status
    const [applyGiftCard, setApplyGiftCard] = useState(false); // Trigger for applying gift card
    const user = JSON.parse(localStorage.getItem('user'));

    const { response, loading, error } = useFetch(
        applyGiftCard ? useGiftCardUrl : null,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ gift_card_code: giftCardCode, user_id: user?.id }),
        },
        [applyGiftCard]
    );

    useEffect(() => {
        if (response) {
            navigate('/profile', { state: {redirect_to:"/currentPlan"} });
            handleCloseGiftCardDialog();
            setApplyGiftCard(false); // Reset trigger
        } else if (error) {
            setErrorMessage(error.message || t('useGiftCard.error.default'));
            setApplyGiftCard(false); // Reset trigger
        }
    }, [response, error, navigate, t]);

    const handleOpenLoginDialog = () => {
        setLoginDialogOpen(true);
    };

    const handleCloseLoginDialog = () => {
        setLoginDialogOpen(false);
    };

    const handleOpenGiftCardDialog = () => {
        setDialogOpen(true);
        setErrorMessage('');
    };

    const handleCloseGiftCardDialog = () => {
        setDialogOpen(false);
        setGiftCardCode('');
    };

    const handleApplyGiftCard = () => {
        setApplyGiftCard(true); // Trigger API call
    };
    const googleSuccessHandler = (r) => {
        handleGoogleSuccess(r).then(r => r)
        setIsLoggedIn(true);
        handleCloseLoginDialog();
        handleOpenGiftCardDialog();
    }
    const handleUseGiftCardClick = () => {
        if (isLoggedIn) {
            handleOpenGiftCardDialog();
        } else {
            handleOpenLoginDialog();
        }
    };

    return (
        <>
            {/* Floating Button */}
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                    zIndex: 1000,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#1976d2', // Blue background color
                    color: 'white', // White text color
                    borderRadius: 3,
                    border: 2,
                    padding: 1,
                    cursor: 'pointer', // Ensure pointer cursor
                    '&:hover': {
                        opacity: 0.9, // Slight hover effect
                    },
                }}
                onClick={handleUseGiftCardClick}
            >
                <Typography
                    sx={{
                        fontSize: '1.2rem',
                        marginRight: 1,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {t('useGiftCard.buttonLabel')}
                </Typography>
                <img
                    src={'/icons/use-gift-card.webp'}
                    style={{
                        width: 50,
                        height: 'auto',
                        margin: 0,
                        padding: 0,
                    }}
                    alt={'Use Gift Card'}
                />
            </Box>

            {/* Login Dialog */}
            <LoginDialog
                open={loginDialogOpen}
                onClose={handleCloseLoginDialog}
                loading={false}
                googleSuccessHandler={googleSuccessHandler}
                clientId={clientId}
                setLoggedIn={(status) => {
                    setIsLoggedIn(status);
                    if (status) {
                        handleCloseLoginDialog();
                        handleOpenGiftCardDialog(); // Automatically open gift card dialog after registration
                    }
                }}
                isLogin={false} // Set the dialog to open in registration mode
            />

            {/* Gift Card Dialog */}
            <Dialog open={dialogOpen} onClose={handleCloseGiftCardDialog} maxWidth="sm" fullWidth>
                <DialogTitle>{t('useGiftCard.dialogTitle')}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        {t('useGiftCard.instructions')}
                    </Typography>
                    <TextField
                        fullWidth
                        label={t('useGiftCard.inputLabel')}
                        variant="outlined"
                        value={giftCardCode}
                        onChange={(e) => setGiftCardCode(e.target.value)}
                        error={!!errorMessage}
                        helperText={errorMessage}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseGiftCardDialog} color="secondary">
                        {t('useGiftCard.cancelButton')}
                    </Button>
                    <Button
                        onClick={handleApplyGiftCard}
                        color="primary"
                        variant="contained"
                        disabled={!giftCardCode || loading}
                    >
                        {loading ? t('useGiftCard.loading') : t('useGiftCard.applyButton')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UseGiftCard;
