export const baseUrl = 'https://shtapgo-back.vercel.app/api'
export const originUrl = 'https://shtapgo.com/'
export const usersUrl = `${baseUrl}/users/`
export const googleLoginUrl = `${baseUrl}/login_google/`
export const loginUrl = `${baseUrl}/login/`
export const registerUrl = `${baseUrl}/register/`
export const logoutUrl = `${baseUrl}/logout/`
export const profileUrl = `${baseUrl}/profile/`
export const serviceRequestsUrl = `${baseUrl}/service_requests/`
export const servicesUrl = `${baseUrl}/services/`
export const paymentIntentsUrl = `${baseUrl}/payment_intents/`
export const refreshUrl = `${baseUrl}/token/refresh/`
export const plansUrl = `${baseUrl}/plans/`
export const userPlansUrl = `${baseUrl}/user_plans/`
export const useGiftCardUrl = `${baseUrl}/use_gift_card/`