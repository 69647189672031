import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Button,
    Box,
    Typography,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';
import { useTranslation } from "react-i18next";
import { useMembershipPlans } from "../../mocks/plans"; // Import membership plans

const PaymentStatus = () => {
    const { status } = useParams(); // Capture the "status" parameter from the URL
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [giftCardData, setGiftCardData] = useState(null);
    const membershipPlans = useMembershipPlans(); // Get membership plans

    useEffect(() => {
        if (status === 'gift_card') {
            // Extract gift card data from the query parameters
            const params = new URLSearchParams(window.location.search);
            const giftCardString = params.get("gift_card");
            if (giftCardString) {
                try {
                    // Decode URI component and parse JSON string
                    const parsedGiftCard = JSON.parse(decodeURIComponent(giftCardString));

                    // Map plan ID to plan title
                    const planTitle = membershipPlans.find(plan => plan.id === parsedGiftCard.plan)?.title || t('payment.giftCard.noPlan');
                    setGiftCardData({ ...parsedGiftCard, plan: planTitle });
                } catch (error) {
                    console.error("Failed to parse gift card data:", error);
                }
            }
        }
    }, [status, membershipPlans, t]);

    const handleGoToMainMenu = () => {
        navigate('/'); // Redirect to the main menu (homepage)
    };

    const renderDialogContent = () => {
        switch (status) {
            case 'gift_card':
                return (
                    <>
                        <DialogTitle>{t('payment.giftCard.title')}</DialogTitle>
                        <DialogContent>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                                {t('payment.giftCard.content')}
                            </Typography>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                                {t('payment.giftCard.code')}: {giftCardData?.code || t('payment.giftCard.noCode')}
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 1 }}>
                                {t('payment.giftCard.plan')}: {giftCardData?.plan || t('payment.giftCard.noPlan')}
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 1 }}>
                                {t('payment.giftCard.fullName')}: {giftCardData?.full_name || t('payment.giftCard.noFullName')}
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 1 }}>
                                {t('payment.giftCard.phone')}: {giftCardData?.phone || t('payment.giftCard.noPhone')}
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleGoToMainMenu} color="primary" variant="contained">
                                {t('payment.goToMainMenu')}
                            </Button>
                        </DialogActions>
                    </>
                );
            default:
                return <Typography variant="body1">{t('payment.invalidStatus')}</Typography>;
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            position: 'relative'
        }}>
            {/* Background Loading Circle */}
            <CircularProgress sx={{ position: 'absolute', zIndex: 0 }} />

            {/* Dialog */}
            <Dialog open={true} maxWidth="xs" fullWidth>
                {renderDialogContent()}
            </Dialog>
        </Box>
    );
};

export default PaymentStatus;
