import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Divider,
    Button,
    CircularProgress,
    TextField,
    Box,
    Typography,
    Link
} from '@mui/material';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import CloseIcon from '@mui/icons-material/Close';
import CallIcon from '@mui/icons-material/Call';
import { useTranslation } from "react-i18next";
import { userLogin } from "../../common/api";

const LoginDialog = ({
                         open,
                         onClose,
                         loading,
                         googleSuccessHandler,
                         clientId,
                         setLoggedIn,
                         isLogin: initialIsLogin = true, // Initialize login mode
                         onlyRegister = false // Control whether only registration is shown
                     }) => {
    const { t } = useTranslation();
    const [isLogin, setIsLogin] = useState(onlyRegister ? false : initialIsLogin);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const toggleMode = () => {
        if (!onlyRegister) {
            setIsLogin(!isLogin);
            setError('');
        }
    };

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validatePhone = (phone) => /^(?:\+?\d{3})?0?\d{8}$/.test(phone);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!username || !password) {
            setError(t('membership.dialog.fillRequiredFields'));
            return;
        }

        if (!validateEmail(username) && !validatePhone(username)) {
            setError(t('membership.dialog.invalidUsername'));
            return;
        }

        if (!isLogin && password !== confirmPassword) {
            setError(t('membership.dialog.passwordMismatch'));
            return;
        }

        try {
            const response = isLogin
                ? await userLogin(username, password, 'login')
                : await userLogin(username, password, 'register');

            if (response.success) {
                setLoggedIn(true);
                onClose();
            } else {
                setError(response.message || t('membership.dialog.loginFailed'));
            }
        } catch (err) {
            setError(t('membership.dialog.serverError'));
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="login-dialog-title"
            maxWidth="xs"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '16px',
                    padding: '16px',
                    backgroundColor: '#f9f9f9',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                }
            }}
        >
            <DialogTitle id="login-dialog-title" sx={{ textAlign: 'center', fontWeight: 'bold', pb: 0 }}>
                {t(onlyRegister ? 'membership.google.registerToContinue' : isLogin ? 'membership.google.loginToContinue' : 'membership.google.registerToContinue')}
                <Typography variant="body1" sx={{ mb: 3 }}>
                    {t(onlyRegister ? 'membership.google.registerPrompt' : isLogin ? 'membership.google.loginPrompt' : 'membership.google.registerPrompt')}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ textAlign: 'center' }}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: 2 }}>
                        <TextField
                            label={t('membership.username')}
                            variant="outlined"
                            fullWidth
                            sx={{ mb: 1 }}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <Typography variant="caption" color="textSecondary" sx={{ mb: 2 }}>
                            {t('membership.dialog.usernameInfo')}
                        </Typography>
                        <TextField
                            label={t('membership.password')}
                            type="password"
                            variant="outlined"
                            fullWidth
                            sx={{ mb: 2 }}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {!isLogin && (
                            <TextField
                                label={t('membership.confirmPassword')}
                                type="password"
                                variant="outlined"
                                fullWidth
                                sx={{ mb: 2 }}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        )}
                        {error && (
                            <Typography variant="body2" color="error" sx={{ mb: 2 }}>
                                {error}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2, borderRadius: '20px', padding: '8px 16px' }}
                        >
                            {t(isLogin ? 'membership.loginButton' : 'membership.registerButton')}
                        </Button>
                        {!onlyRegister && (
                            <DialogActions sx={{ justifyContent: 'center', pt: '1rem' }}>
                                <Button onClick={toggleMode} color="secondary" sx={{ borderRadius: '20px' }}>
                                    {t(isLogin ? 'membership.dialog.noAccount' : 'membership.dialog.haveAccount')}
                                </Button>
                            </DialogActions>
                        )}
                    </Box>
                )}
            </DialogContent>
            <Divider>{t('membership.dialog.or')}</Divider>
            <DialogContent sx={{ textAlign: 'center' }}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <GoogleOAuthProvider clientId={clientId}>
                            <GoogleLogin
                                theme="filled_blue"
                                size="large"
                                shape="circle"
                                width={350}
                                onSuccess={googleSuccessHandler}
                                onError={() => console.log('Login Failed')}
                            />
                        </GoogleOAuthProvider>
                    </>
                )}
            </DialogContent>
            <Divider>{t('membership.dialog.or')}</Divider>
            <Typography variant="h4" sx={{ textAlign: 'center', mt: 2, color: '#1976d2', mb: 2 }}>
                <Link href={`tel:${t('navTabs.contactUs.content.phone')}`} color="inherit">
                    <CallIcon sx={{ fontSize: '2rem', verticalAlign: 'middle', color: '#1976d2' }} />
                    {t('navTabs.contactUs.content.phone')}
                </Link>
            </Typography>
        </Dialog>
    );
};

export default LoginDialog;